import React from 'react';
import { Button, Input, Space, Row, Col } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

function DynamicField({ label, items, setItems }) {
  const addItem = () => {
    setItems([...items, { id: Date.now().toString(), value: '' }]);
  };

  const removeItem = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const updateItem = (id, value) => {
    setItems(items.map((item) => (item.id === id ? { ...item, value } : item)));
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <h4>{label}</h4>
      {items.map((item) => (
        <div key={item.id} style={{ marginBottom: '8px' }}>
          <Row gutter={16}>
            <Col span={20}>
              <Input
                value={item.value}
                onChange={(e) => updateItem(item.id, e.target.value)}
                placeholder={label}
              />
            </Col>
            <Col span={4}>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => removeItem(item.id)}
                danger
              />
            </Col>
          </Row>
        </div>
      ))}
      <Button onClick={addItem} icon={<PlusOutlined />}>
        Add {label}
      </Button>
    </div>
  );
}

export default DynamicField;
