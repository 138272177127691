// Function to download a JSON file
export const downloadJson = (formData, fileName = 'form-data.json') => {
    const jsonStr = JSON.stringify(formData, null, 2); // Convert the form data to a JSON string
    const blob = new Blob([jsonStr], { type: 'application/json' }); // Create a blob from the JSON string
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click(); // Programmatically click the link to trigger the download
  };
  
  // Function to load the uploaded JSON and parse it
  export const loadJson = (event, setFormData) => {
    const file = event.target.files[0]; // Get the file from the file input
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const jsonData = JSON.parse(e.target.result); // Parse the JSON file
        setFormData(jsonData); // Set the form data with the JSON content
      };
      reader.readAsText(file); // Read the file as text
    }
  };
  