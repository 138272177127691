export const templates = {
    personalizada: [],
    default: [
      { id: '1', name: 'Bienvenida', description: 'Saludo inicial y presentación del asesor' },
      { id: '2', name: 'Identificación de necesidades', description: 'Preguntas para entender las necesidades del cliente' },
      { id: '3', name: 'Presentación de productos', description: 'Explicación de productos o servicios relevantes' },
      { id: '4', name: 'Manejo de objeciones', description: 'Abordar dudas o preocupaciones del cliente' },
      { id: '5', name: 'Cierre de venta', description: 'Propuesta final y solicitud de la venta' },
      { id: '6', name: 'Seguimiento', description: 'Confirmación de la venta y próximos pasos' }
    ],
    restaurante: [
      { id: '1', name: 'Bienvenida', description: 'Saludo y presentación del restaurante' },
      { id: '2', name: 'Reserva', description: 'Consulta de disponibilidad y detalles de la reserva' },
      { id: '3', name: 'Menú', description: 'Presentación de opciones del menú y especialidades' },
      { id: '4', name: 'Pedido', description: 'Toma del pedido y preferencias del cliente' },
      { id: '5', name: 'Confirmación', description: 'Resumen del pedido y confirmación' },
      { id: '6', name: 'Despedida', description: 'Agradecimiento y cierre de la conversación' }
    ],
    agendamiento: [
      { id: '1', name: 'Saludo', description: 'Bienvenida y motivo de la llamada' },
      { id: '2', name: 'Tipo de cita', description: 'Identificación del tipo de cita requerida' },
      { id: '3', name: 'Disponibilidad', description: 'Consulta de fechas y horarios disponibles' },
      { id: '4', name: 'Datos del cliente', description: 'Recopilación de información del cliente' },
      { id: '5', name: 'Confirmación', description: 'Resumen y confirmación de la cita' },
      { id: '6', name: 'Recordatorio', description: 'Información sobre recordatorios y política de cancelación' }
    ]
  };