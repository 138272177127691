import React from 'react';
import { Input, Button, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;

function DynamicSections({ sections = [], setSections }) {
  const addSection = () => {
    setSections([...sections, { id: Date.now().toString(), title: '', content: '' }]);
  };

  const removeSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  const updateSection = (id, field, value) => {
    setSections(sections.map((section) =>
      section.id === id ? { ...section, [field]: value } : section
    ));
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <h4>Información adicional</h4>
      {sections.map((section) => (
        <div key={section.id} style={{ marginBottom: '16px' }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input
              placeholder="Section Title"
              value={section.title}
              onChange={(e) => updateSection(section.id, 'title', e.target.value)}
              addonBefore="###"
            />
            <TextArea
              placeholder="Section Content"
              value={section.content}
              onChange={(e) => updateSection(section.id, 'content', e.target.value)}
              rows={4}
            />
            <Button icon={<DeleteOutlined />} onClick={() => removeSection(section.id)} danger>
              Remove Section
            </Button>
          </Space>
        </div>
      ))}
      <Button onClick={addSection} icon={<PlusOutlined />}>
        Add additional information section
      </Button>
    </div>
  );
}

export default DynamicSections;
