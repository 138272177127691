export const predefinedTemplates = {
    "Venta por Defecto": [
      { key: "transaction_id", type: "string", description: "ID único de la transacción" },
      { key: "date", type: "string", description: "Fecha y hora de la venta" },
      { key: "customer", type: "object", description: "Información del cliente", nested: [
        { key: "name", type: "string", description: "Nombre del cliente" },
        { key: "email", type: "string", description: "Correo electrónico del cliente" },
        { key: "phone", type: "string", description: "Número de teléfono del cliente" }
      ]},
      { key: "items", type: "array", description: "Productos vendidos", nested: [
        { key: "product_id", type: "string", description: "ID del producto" },
        { key: "name", type: "string", description: "Nombre del producto" },
        { key: "quantity", type: "number", description: "Cantidad vendida" },
        { key: "price", type: "number", description: "Precio unitario" },
        { key: "subtotal", type: "number", description: "Subtotal del ítem" }
      ]},
      { key: "total", type: "number", description: "Total de la venta" },
      { key: "payment_method", type: "string", description: "Método de pago" },
      { key: "status", type: "string", description: "Estado de la venta" }
    ],
    "Venta Restaurante": [
      { key: "order_id", type: "string", description: "ID único del pedido" },
      { key: "table_number", type: "number", description: "Número de mesa" },
      { key: "server_name", type: "string", description: "Nombre del mesero" },
      { key: "order_time", type: "string", description: "Hora del pedido" },
      { key: "items", type: "array", description: "Platos ordenados", nested: [
        { key: "dish_id", type: "string", description: "ID del plato" },
        { key: "name", type: "string", description: "Nombre del plato" },
        { key: "quantity", type: "number", description: "Cantidad ordenada" },
        { key: "price", type: "number", description: "Precio unitario" },
        { key: "special_instructions", type: "string", description: "Instrucciones especiales" }
      ]},
      { key: "subtotal", type: "number", description: "Subtotal de la orden" },
      { key: "tax", type: "number", description: "Impuestos" },
      { key: "tip", type: "number", description: "Propina" },
      { key: "total", type: "number", description: "Total de la orden" },
      { key: "payment_status", type: "string", description: "Estado del pago" },
      { key: "order_status", type: "string", description: "Estado de la orden" }
    ],
    "Agendamiento de Citas": [
      { key: "appointment_id", type: "string", description: "ID único de la cita" },
      { key: "client", type: "object", description: "Información del cliente", nested: [
        { key: "name", type: "string", description: "Nombre del cliente" },
        { key: "email", type: "string", description: "Correo electrónico del cliente" },
        { key: "phone", type: "string", description: "Número de teléfono del cliente" }
      ]},
      { key: "service", type: "object", description: "Información del servicio", nested: [
        { key: "service_id", type: "string", description: "ID del servicio" },
        { key: "name", type: "string", description: "Nombre del servicio" },
        { key: "duration", type: "number", description: "Duración en minutos" },
        { key: "price", type: "number", description: "Precio del servicio" }
      ]},
      { key: "staff", type: "object", description: "Información del personal asignado", nested: [
        { key: "staff_id", type: "string", description: "ID del personal" },
        { key: "name", type: "string", description: "Nombre del personal" }
      ]},
      { key: "date", type: "string", description: "Fecha de la cita" },
      { key: "start_time", type: "string", description: "Hora de inicio" },
      { key: "end_time", type: "string", description: "Hora de finalización" },
      { key: "status", type: "string", description: "Estado de la cita" },
      { key: "notes", type: "string", description: "Notas adicionales" },
      { key: "reminder_sent", type: "boolean", description: "Indicador de recordatorio enviado" }
    ]
  };