import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

function FormHeader() {
  return (
    <div>
      <h2 level={2}>AI Assistant Setup</h2>
    </div>
  );
}

export default FormHeader;
