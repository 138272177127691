import { useState, useEffect } from 'react';

const LOCAL_STORAGE_KEY = 'ai_assistant_form_data';

export const useFormState = () => {
  const loadState = () => {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) {
      return JSON.parse(savedState);
    }
    return {
      name: '',
      role: '',
      marca: '',
      objectives: [{ id: '1', value: '' }],
      restrictions: [{ id: '1', value: '' }],
      additionalInstructions: [{ id: '1', value: '' }],
      humanHandoff: [{ id: '1', value: '' }],
      products: [{ id: '1', name: '', price: '', description: '', availability: '' }],
      conversationStages: [{ id: '1', name: '', description: '' }],
      companyLocations: [{ id: '1', name: '', address: '', country: '', city: '', phone: '', email: '', status: 'active' }],
      jsonStructure: [{ key: '', type: 'string', description: '', nested: [] }],
      dynamicSections: [] // Add this line to initialize dynamicSections
    };
  };

  const [state, setState] = useState(loadState);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  const updateState = (key, value) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  return {
    ...state,
    setName: (value) => updateState('name', value),
    setObjectives: (value) => updateState('objectives', value),
    setRestrictions: (value) => updateState('restrictions', value),
    setAdditionalInstructions: (value) => updateState('additionalInstructions', value),
    setHumanHandoff: (value) => updateState('humanHandoff', value),
    setProducts: (value) => updateState('products', value),
    setConversationStages: (value) => updateState('conversationStages', value),
    setCompanyLocations: (value) => updateState('companyLocations', value),
    setJsonStructure: (value) => updateState('jsonStructure', value),
    setMarca: (value) => updateState('marca', value),
    setRole: (value) => updateState('role', value),
    setDynamicSections: (value) => updateState('dynamicSections', value),
  };
};
