import React, { useState, useEffect } from 'react';
import { Card, Button, Input, Select, Form, Popconfirm, Row, Col } from 'antd';
import { PlusOutlined, EditOutlined, CopyOutlined, DeleteOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

const LocationCard = ({ location, isEditing, onSave, onEdit, onCancel, onDuplicate, onDelete }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue(location);
    }
  }, [isEditing, location, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(location.id, values);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  return (
    <Card
      key={location.id}
      title={isEditing ? "Edit Location" : location.name}
      extra={
        isEditing ? (
          <span>
            <Button icon={<SaveOutlined />} onClick={handleSave} style={{ marginRight: 8 }} />
            <Button icon={<CloseOutlined />} onClick={onCancel} />
          </span>
        ) : (
          <span>
            <Button icon={<EditOutlined />} onClick={() => onEdit(location)} style={{ marginRight: 8 }} />
            <Button icon={<CopyOutlined />} onClick={() => onDuplicate(location)} style={{ marginRight: 8 }} />
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(location.id)}>
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        )
      }
      style={{ marginBottom: 16 }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
              {isEditing ? <Input /> : location.name}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please enter Address' }]}>
              {isEditing ? <Input /> : location.address}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Please enter Country' }]}>
              {isEditing ? <Input /> : location.country}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter City' }]}>
              {isEditing ? <Input /> : location.city}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please enter Phone' }]}>
              {isEditing ? <Input /> : location.phone}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid Email' }]}>
              {isEditing ? <Input /> : location.email}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select Status' }]}>
              {isEditing ? (
                <Select style={{ width: 120 }}>
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              ) : (
                location.status
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function LocationForm({ locations, setLocations }) {
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = (key, values) => {
    const newData = [...locations];
    const index = newData.findIndex((item) => key === item.id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...values });
      setLocations(newData);
      setEditingKey('');
    }
  };

  const addLocation = () => {
    const newLocation = {
      id: Date.now().toString(),
      name: '',
      address: '',
      country: '',
      city: '',
      phone: '',
      email: '',
      status: 'active'
    };
    setLocations([...locations, newLocation]);
    setEditingKey(newLocation.id);
  };

  const duplicateLocation = (record) => {
    const newLocation = {
      ...record,
      id: Date.now().toString(),
      name: `${record.name} (Copy)`
    };
    setLocations([...locations, newLocation]);
  };

  const deleteLocation = (key) => {
    setLocations(locations.filter((item) => item.id !== key));
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <h4>Ubicaciones de la compañía</h4>
      <Button 
        onClick={addLocation} 
        icon={<PlusOutlined />} 
        style={{ marginBottom: 16, border: '1px dashed #d9d9d9', background: 'white' }}
      >
        Add Location
      </Button>
      {locations.map(location => (
        <LocationCard
          key={location.id}
          location={location}
          isEditing={isEditing(location)}
          onSave={save}
          onEdit={edit}
          onCancel={cancel}
          onDuplicate={duplicateLocation}
          onDelete={deleteLocation}
        />
      ))}
    </div>
  );
}

export default LocationForm;
