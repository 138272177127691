import React, { useState } from 'react';
import { Input, Select, Button, Space, Row, Col, message, Modal, Empty } from 'antd';
import { PlusOutlined, DeleteOutlined, ImportOutlined, FileOutlined } from '@ant-design/icons';
import { predefinedTemplates } from '../utils/JsonPredefinedResponseTemplates';

const { Option } = Select;
const { TextArea } = Input;

function JsonResponseStructure({ structure = [], onChange }) {
  const [jsonInput, setJsonInput] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  // Add a new field to the structure
  const addField = (parentIndex = []) => {
    const newField = { key: '', type: 'string', description: '', nested: [] };
    const updatedStructure = [...structure];
    let current = updatedStructure;
    parentIndex.forEach((i) => {
      current = current[i].nested;
    });
    current.push(newField);
    onChange(updatedStructure);
  };

  // Remove a field from the structure
  const removeField = (index, parentIndex = []) => {
    const updatedStructure = [...structure];
    let current = updatedStructure;
    parentIndex.forEach((i) => {
      current = current[i].nested;
    });
    current.splice(index, 1);
    onChange(updatedStructure);
  };

  // Update a field's key, type, or description
  const updateField = (index, field, value, parentIndex = []) => {
    const updatedStructure = [...structure];
    let current = updatedStructure;
    parentIndex.forEach((i) => {
      current = current[i].nested;
    });
    current[index][field] = value;
    onChange(updatedStructure);
  };

  // Render nested fields recursively
  const renderFields = (fields, parentIndex = []) => {
    return fields.map((field, index) => {
      const currentIndex = [...parentIndex, index];
      return (
        <div key={index} style={{ marginBottom: '10px' }}>
          <Row gutter={16} align="middle">
            <Col span={6}>
              <Input
                placeholder="Key Name"
                value={field.key}
                onChange={(e) => updateField(index, 'key', e.target.value, parentIndex)}
              />
            </Col>
            <Col span={6}>
              <Select
                value={field.type}
                onChange={(value) => updateField(index, 'type', value, parentIndex)}
                style={{ width: '100%' }}
              >
                <Option value="string">String</Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="object">Object</Option>
                <Option value="array">Array</Option>
              </Select>
            </Col>
            <Col span={8}>
              <Input
                placeholder="Description"
                value={field.description}
                onChange={(e) => updateField(index, 'description', e.target.value, parentIndex)}
              />
            </Col>
            <Col span={4}>
              <Space>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => addField(currentIndex)}
                  disabled={field.type !== 'object' && field.type !== 'array'}
                />
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => removeField(index, parentIndex)}
                  danger
                />
              </Space>
            </Col>
          </Row>
          {(field.type === 'object' || field.type === 'array') && (
            <div style={{ marginLeft: '20px', marginTop: '10px' }}>
              {renderFields(field.nested, currentIndex)}
            </div>
          )}
        </div>
      );
    });
  };

  // Generate structure from JSON
  const generateStructureFromJson = () => {
    try {
      const parsedJson = JSON.parse(jsonInput);
      const generatedStructure = parseJsonObject(parsedJson);
      onChange(generatedStructure);
      message.success('Structure generated successfully');
      setIsModalVisible(false);
    } catch (error) {
      message.error('Invalid JSON input');
    }
  };

  // Helper function to parse JSON object recursively
  const parseJsonObject = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
      const type = Array.isArray(value) ? 'array' : typeof value;
      const field = { key, type, description: '', nested: [] };

      if (type === 'object' || type === 'array') {
        field.nested = type === 'object' ? parseJsonObject(value) : parseJsonObject(value[0] || {});
      }

      return field;
    });
  };

  const handleTemplateSelection = (templateName) => {
    const selectedTemplate = predefinedTemplates[templateName];
    if (selectedTemplate) {
      onChange(selectedTemplate);
      message.success(`Template "${templateName}" applied successfully`);
    } else {
      message.error('Template not found');
    }
    setIsTemplateModalVisible(false);
  };

  return (
    <div>
      <h4>Estructura de la respuesta JSON</h4>
      
      <Space style={{ marginBottom: '20px' }}>
        <Button 
          onClick={() => setIsModalVisible(true)} 
          icon={<ImportOutlined />}
        >
          Import JSON
        </Button>
        <Button 
          onClick={() => setIsTemplateModalVisible(true)} 
          icon={<FileOutlined />}
        >
          Select Template
        </Button>
      </Space>

      <Modal
        title="Import JSON"
        visible={isModalVisible}
        onOk={generateStructureFromJson}
        onCancel={() => setIsModalVisible(false)}
      >
        <TextArea
          rows={10}
          value={jsonInput}
          onChange={(e) => setJsonInput(e.target.value)}
          placeholder="Paste your JSON here"
        />
      </Modal>

      <Modal
        title="Select Predefined Template"
        visible={isTemplateModalVisible}
        onCancel={() => setIsTemplateModalVisible(false)}
        footer={null}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {Object.keys(predefinedTemplates).map((templateName) => (
            <Button 
              key={templateName} 
              onClick={() => handleTemplateSelection(templateName)}
              style={{ width: '100%' }}
            >
              {templateName}
            </Button>
          ))}
        </Space>
      </Modal>

      {structure.length > 0 ? (
        <>
          {renderFields(structure)}
          <Button type="dashed" onClick={() => addField()} icon={<PlusOutlined />} style={{ marginTop: '10px' }}>
            Add Field
          </Button>
        </>
      ) : (
        <Empty
          description="No JSON structure defined"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        >
          <Button type="primary" onClick={() => addField()} icon={<PlusOutlined />}>
            Add First Field
          </Button>
        </Empty>
      )}
    </div>
  );
}

export default JsonResponseStructure;
