import React, { useState, useEffect } from 'react';
import { Input, Button, Space, Form, Typography, Select, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined, ReloadOutlined } from '@ant-design/icons';
import { templates } from '../utils/conversationStagesTemplates';

const { Title } = Typography;
const { Option } = Select;

function StageForm({ stages, setStages }) {
  const [selectedTemplate, setSelectedTemplate] = useState('personalizada');
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  useEffect(() => {
    if (stages.length === 0 && selectedTemplate !== 'personalizada') {
      setStages(templates[selectedTemplate]);
    }
  }, [selectedTemplate]);

  const handleTemplateChange = (value) => {
    setSelectedTemplate(value);
    setStages(templates[value]);
  };

  const addStage = () => {
    setStages([...stages, { id: Date.now().toString(), name: '', description: '' }]);
  };

  const removeStage = (id) => {
    setStages(stages.filter((stage) => stage.id !== id));
  };

  const updateStage = (id, field, value) => {
    setStages(stages.map((stage) =>
      stage.id === id ? { ...stage, [field]: value } : stage
    ));
  };

  const moveStage = (index, direction) => {
    const newStages = [...stages];
    const [movedStage] = newStages.splice(index, 1);
    newStages.splice(index + direction, 0, movedStage);
    setStages(newStages);
  };

  const showResetModal = () => {
    setIsResetModalVisible(true);
  };

  const handleResetOk = () => {
    setStages([]);
    setSelectedTemplate('personalizada');
    setIsResetModalVisible(false);
  };

  const handleResetCancel = () => {
    setIsResetModalVisible(false);
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <h4>Estados de la conversación</h4>
      <Space align="baseline" style={{ marginBottom: '16px', width: '100%' }}>
        <Form.Item label="Seleccionar plantilla" style={{ marginBottom: 0, marginRight: '16px' }}>
          <Select value={selectedTemplate} onChange={handleTemplateChange} style={{ width: 200 }}>
            <Option value="personalizada">Personalizada</Option>
            <Option value="default">Venta por defecto</Option>
            <Option value="restaurante">Venta Restaurante</Option>
            <Option value="agendamiento">Agendamiento de citas</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Reiniciar" style={{ marginBottom: 0 }}>
          <Button icon={<ReloadOutlined />} onClick={showResetModal}>
            Resetear Estados
          </Button>
        </Form.Item>
      </Space>
      {stages.map((stage, index) => (
        <Space key={stage.id} style={{ marginBottom: '16px', width: '100%' }} direction="vertical">
          <Title level={5}>Estado {index + 1}</Title>
          <Form layout="vertical" style={{ width: '100%' }}>
            <Form.Item  style={{ marginBottom: '8px' }}>
              <Input
                placeholder="Nombre del estado"
                value={stage.name}
                onChange={(e) => updateStage(stage.id, 'name', e.target.value)}
              />
            </Form.Item>
            <Form.Item  style={{ marginBottom: '8px' }}>
              <Input.TextArea
                placeholder="Descripción del estado"
                value={stage.description}
                onChange={(e) => updateStage(stage.id, 'description', e.target.value)}
                rows={2}
              />
            </Form.Item>
          </Form>
          <Space>
            <Button icon={<DeleteOutlined />} onClick={() => removeStage(stage.id)} />
            <Button 
              icon={<ArrowUpOutlined />} 
              onClick={() => moveStage(index, -1)} 
              disabled={index === 0}
            />
            <Button 
              icon={<ArrowDownOutlined />} 
              onClick={() => moveStage(index, 1)} 
              disabled={index === stages.length - 1}
            />
          </Space>
        </Space>
      ))}
      <Button 
        onClick={addStage} 
        icon={<PlusOutlined />} 
      >
        Agregar Estado
      </Button>
      <Modal
        title="Advertencia"
        visible={isResetModalVisible}
        onOk={handleResetOk}
        onCancel={handleResetCancel}
        okText="Sí, resetear"
        cancelText="Cancelar"
      >
        <p>¿Estás seguro de que quieres resetear todos los estados? Esta acción no se puede deshacer y te llevará a la plantilla personalizada.</p>
      </Modal>
    </div>
  );
}

export default StageForm;
