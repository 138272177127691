import React, { useState, useEffect } from 'react';
import { Button, Form, Upload, Layout, Row, Col, Divider, Space, message, Input, Modal } from 'antd';
import moment from 'moment'; // Make sure to import moment if not already done
import { UploadOutlined, DownloadOutlined, CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { ConfigProvider } from 'antd';
import FormHeader from './components/FormHeader';
import DynamicField from './components/DynamicField';
import ProductForm from './components/ProductForm';
import StageForm from './components/StageForm';
import LocationForm from './components/LocationForm';
import JsonResponseStructure from './components/JsonResponseStructure';
import DynamicSections from './components/DynamicSections';
import { useFormState } from './hooks/useFormState';
import { generatePrompt } from './utils/promptTemplate';
import { downloadJson } from './utils/jsonUtils';
import 'moment/locale/es'; // Import the locale

const { Header, Content } = Layout;

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileName, setFileName] = useState('');
  const [prompt, setPrompt] = useState('');
  const formState = useFormState();
  const { dynamicSections, ...otherFormState } = formState;

  useEffect(() => {
    const generatedPrompt = generatePrompt({...otherFormState, dynamicSections});
    setPrompt(generatedPrompt);
  }, [otherFormState, dynamicSections]);

  const handleDownloadJson = () => {
    const dataToDownload = {
      name: formState.name,
      ...formState
    };
    // Create a default filename with "apexai", marca, and current date-time in a more readable format
    const defaultFileName = `${formState.marca ? `${formState.marca}` : ''}_apexai_${moment().format('LLLL:ss a')}.json`;

    setFileName(defaultFileName);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    downloadJson(formState, fileName);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUploadJson = (info) => {
    const { file } = info;
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        console.log('Parsed JSON data:', jsonData);

        Object.keys(jsonData).forEach(key => {
          if (formState[`set${key.charAt(0).toUpperCase() + key.slice(1)}`]) {
            formState[`set${key.charAt(0).toUpperCase() + key.slice(1)}`](jsonData[key]);
          }
        });

        message.success('JSON file uploaded successfully');
      } catch (error) {
        console.error('Error parsing JSON:', error);
        message.error('Failed to parse JSON file');
      }
    };
    reader.readAsText(file);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(prompt).then(() => {
      message.success('Prompt copied to clipboard');
    }).catch(() => {
      message.error('Failed to copy prompt');
    });
  };

  const clearForm = () => {
    // Clear local storage
    localStorage.removeItem('ai_assistant_form_data');

    // Reset all form fields
    formState.setName('');
    formState.setMarca(''); // Add this line to clear the marca field
    formState.setObjectives([]);
    formState.setRestrictions([]);
    formState.setAdditionalInstructions([]);
    formState.setHumanHandoff([]);
    formState.setProducts([]);
    formState.setConversationStages([]);
    formState.setCompanyLocations([]);
    formState.setJsonStructure([]);
    formState.setDynamicSections([]);

    message.success('Form cleared');
  };

  return (
    <ConfigProvider>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ 
          padding: '0 20px', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          backgroundColor: 'white' // Add this line to make the background white
        }}>
          <div style={{ width: '33%' }}></div>
          <div style={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
            <img
              src="/apexai.png"
              alt="Logo"
              style={{ height: '50px' }}
            />
          </div>
          <div style={{ width: '33%' }}></div>
        </Header>

        <Content style={{ padding: '50px' }}>
          <Row gutter={24}>
            {/* Left column: Form */}
            <Col xs={24} lg={12}>
              <div style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
              <FormHeader />
                
                <Form layout="vertical">

                  {/* Marca Field */}
                  <h4>Marca:</h4>
                  <Form.Item required>
                    <Input
                      value={formState.marca}
                      onChange={(e) => formState.setMarca(e.target.value)}
                      placeholder="Ingrese la marca"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  <Divider />

                  {/* AI Assistant Name */}
                  <h4>Tu nombre es:</h4>
                  <Form.Item required>
                    <Input
                      value={formState.name}
                      onChange={(e) => formState.setName(e.target.value)}
                      placeholder="Ej. Camila Diaz asistente comercial de ..."
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  {/* AI Assistant Role */}
                  <h4>Tu rol es:</h4>
                  <Form.Item required>
                    <Input
                      value={formState.role}
                      onChange={(e) => formState.setRole(e.target.value)}
                      placeholder="Ej. asistente comercial, servicio al cliente, etc."
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  <Divider />

                  {/* Objectives Section */}
                  <DynamicField label="Objetivos" items={formState.objectives} setItems={formState.setObjectives} />

                  <Divider />

                  {/* Restrictions Section */}
                  <DynamicField label="Restricciones" items={formState.restrictions} setItems={formState.setRestrictions} />

                  <Divider />

                  {/* Additional Instructions Section */}
                  <DynamicField label="Instrucciones adicionales" items={formState.additionalInstructions} setItems={formState.setAdditionalInstructions} />

                  <Divider />

                  {/* Human Handoff Section */}
                  <DynamicField label="Cuando marcar human_needed como true" items={formState.humanHandoff} setItems={formState.setHumanHandoff} />

                  <Divider />

                  {/* Product Section */}
                  <ProductForm products={formState.products} setProducts={formState.setProducts} />

                  <Divider />

                  {/* Conversation Stages Section */}
                  <StageForm stages={formState.conversationStages} setStages={formState.setConversationStages} />

                  <Divider />

                  {/* Company Locations Section */}
                  <LocationForm locations={formState.companyLocations} setLocations={formState.setCompanyLocations} />

                  <Divider />

                     {/* Dynamic Sections */}
                     <DynamicSections sections={dynamicSections} setSections={formState.setDynamicSections} />

<Divider />

                  {/* JSON Response Structure Section */}
                  <JsonResponseStructure structure={formState.jsonStructure} onChange={formState.setJsonStructure} />

                  <Divider />

               
                </Form>
              </div>
            </Col>

            {/* Right column: Generated Prompt and Buttons */}
            <Col xs={24} lg={12}>
              <div style={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                  <h2>Prompt Generado</h2>
                  <Space>
                    <Button 
                      icon={<CopyOutlined />} 
                      onClick={copyToClipboard}
                      disabled={!prompt}
                    >
                      Copy
                    </Button>
                    <Button icon={<DownloadOutlined />} onClick={handleDownloadJson}>
                      Download JSON
                    </Button>
                    <Upload
                      accept=".json"
                      beforeUpload={() => false}
                      onChange={handleUploadJson}
                      showUploadList={false} // Add this line
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload JSON
                      </Button>
                    </Upload>
                    <Button onClick={clearForm} danger>
                      Clear Form
                    </Button>
                  </Space>
                </div>
                <div style={{ flex: 1, overflow: 'auto', padding: '16px', borderRadius: '4px', backgroundColor: '#f0f0f0' }}>
                  <ReactMarkdown>{prompt}</ReactMarkdown>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
      <Modal
        title="Enter filename"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter filename for JSON data"
        />
      </Modal>
    </ConfigProvider>
  );
}

export default App;
