import React, { useState, useEffect } from 'react';
import { Card, Button, Input, Select, Form, Popconfirm, Row, Col, message } from 'antd';
import { PlusOutlined, EditOutlined, CopyOutlined, DeleteOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;

const ProductCard = ({ product, isEditing, onSave, onEdit, onCancel, onDuplicate, onDelete }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue(product);
    }
  }, [isEditing, product, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      onSave(product.id, values);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const validateImageUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = value;
      img.onload = () => resolve();
      img.onerror = () => reject(new Error('Please enter a valid image URL'));
    });
  };

  const validateUrl = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    try {
      new URL(value);
      return Promise.resolve();
    } catch (_) {
      return Promise.reject(new Error('Please enter a valid URL'));
    }
  };

  return (
    <Card
      key={product.id}
      title={isEditing ? "Edit Product" : product.name}
      extra={
        isEditing ? (
          <span>
            <Button icon={<SaveOutlined />} onClick={handleSave} style={{ marginRight: 8 }} />
            <Button icon={<CloseOutlined />} onClick={onCancel} />
          </span>
        ) : (
          <span>
            <Button icon={<EditOutlined />} onClick={() => onEdit(product)} style={{ marginRight: 8 }} />
            <Button icon={<CopyOutlined />} onClick={() => onDuplicate(product)} style={{ marginRight: 8 }} />
            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(product.id)}>
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        )
      }
      style={{ marginBottom: 16 }}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name is required' }]}>
              {isEditing ? <Input /> : product.name}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please enter Price' }]}>
              {isEditing ? <Input /> : product.price}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="sku" label="SKU" rules={[{ required: false, message: 'Please enter SKU' }]}>
              {isEditing ? <Input /> : product.sku}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="availability" label="Availability" rules={[{ required: true, message: 'Please select Availability' }]}>
              {isEditing ? (
                <Select style={{ width: '100%' }}>
                  <Option value="in-stock">In Stock</Option>
                  <Option value="out-of-stock">Out of Stock</Option>
                  <Option value="pre-order">Pre-order</Option>
                </Select>
              ) : (
                product.availability
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item 
              name="imageUrl" 
              label="Link de la imagen" 
              rules={[
                { validator: validateImageUrl }
              ]}
            >
              {isEditing ? <Input /> : product.imageUrl}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              name="productLink" 
              label="Link del producto"
              rules={[
                { validator: validateUrl }
              ]}
            >
              {isEditing ? <Input /> : product.productLink}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label="Description" rules={[{ required: false, message: 'Please enter Description' }]}>
              {isEditing ? <TextArea rows={2} /> : product.description}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

function ProductForm({ products, setProducts }) {
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = (key, values) => {
    const newData = [...products];
    const index = newData.findIndex((item) => key === item.id);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...values });
      setProducts(newData);
      setEditingKey('');
    }
  };

  const addProduct = () => {
    const newProduct = {
      id: Date.now().toString(),
      name: '',
      price: '',
      description: '',
      availability: '',
      sku: '',
      imageUrl: '',
      productLink: ''
    };
    setProducts([...products, newProduct]);
    setEditingKey(newProduct.id);
  };

  const duplicateProduct = (record) => {
    const newProduct = {
      ...record,
      id: Date.now().toString(),
      name: `${record.name} (Copy)`
    };
    setProducts([...products, newProduct]);
  };

  const deleteProduct = (key) => {
    setProducts(products.filter((item) => item.id !== key));
  };

  return (
    <div style={{ marginBottom: '16px' }}>
      <h4>Productos y servicios</h4>
      <Button 
        onClick={addProduct} 
        icon={<PlusOutlined />} 
        style={{ marginBottom: 16, border: '1px dashed #d9d9d9', background: 'white' }}
      >
        Add Product
      </Button>
      {products.map(product => (
        <ProductCard
          key={product.id}
          product={product}
          isEditing={isEditing(product)}
          onSave={save}
          onEdit={edit}
          onCancel={cancel}
          onDuplicate={duplicateProduct}
          onDelete={deleteProduct}
        />
      ))}
    </div>
  );
}

export default ProductForm;
